import React from 'react';
import { Link } from 'gatsby';

import MainLayout from '@hoc/layout';

import * as s from './404.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';

const Home = () => {

  return (
    <MainLayout>
       <section className={`${s.not_found} ${s_wrapper.apply}`}>
            <h1>Nie znaleziono tej strony</h1>

            <Link to='/' className={s_btns.btn_full}>Wróć na stronę główną</Link>

        </section>
    </MainLayout>
  );

}

export default Home;
